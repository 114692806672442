<template>
  <pmis-page>
    <iui-container-new type="css-flex">
      <i-row>
        <i-col width="345px">
          <PrjAllList />
        </i-col>
        <i-spacer />
        <i-col width="450px">
          <pmis-tab-box>
            <template v-slot:title>현장공종분류</template>
            <PrjWorkTypeCl :callback="onSearchCallback"></PrjWorkTypeCl>
          </pmis-tab-box>
        </i-col>
        <i-spacer />
        <i-col>
          <pmis-tab-box>
            <template v-slot:title>상세정보</template>
            <template v-slot:title-right>
              <iui-button value="타현장공종복제" @click="onOtherPrjCopy" />
            </template>
            <DetailInfo />
          </pmis-tab-box>
        </i-col>
      </i-row>
    </iui-container-new>
    <iui-modal name="workTypeChoice" title="표준공종복제" :btns="workTypeModalBtns" sizeType="size1">
      <WorkTypeCl :checkbox="true" :confirm.sync="confirm" :popup="true" :callback="getStdWorkTypeChoiceData" />
    </iui-modal>
    <iui-modal name="otherPrjCopy" title="타현장공종복제" :btns="otherPrjModalBtns" sizeType="size3">
      <OtherPrjCopyPop />
    </iui-modal>
  </pmis-page>
</template>

<script>
import store from './store/ContWorkTypeRegist.js';
import PrjWorkTypeCl from './components/PrjWorkTypeCl.vue';
import DetailInfo from './components/DetailInfo.vue';
import WorkTypeCl from '@/view/sysManage/stdCodeManage/stdWorkTypeRegist/components/WorkTypeCl.vue';
import PrjAllList from '@/view/estmtManage/prjWorkTypeRegist/components/PrjAllList.vue';
import OtherPrjCopyPop from './components/OtherPrjCopyPop.vue';
import {copyStdWorkCl, copyPrjWorkCl} from './api/prjWorkTypeRegist.js';
export default {
  components: {
    PrjWorkTypeCl,
    DetailInfo,
    WorkTypeCl,
    PrjAllList,
    OtherPrjCopyPop,
  },
  data() {
    return {
      workTypeModalBtns: [{name: '확인', callback: this.workTypeChoiceConfirm}],
      otherPrjModalBtns: [{name: '확인', callback: this.otherPrjChoiceConfirm}],
      confirm: false,
    };
  },
  beforeCreate() {
    $init(this, store, 'workType');
    $mapGetters(this, 'workType', ['sheetData', 'status']);
    $mapMutations(this, 'workType', ['setSheetData', 'setFocusKeyCode', 'setStatus', 'initStatus']);
  },
  created() {
    this.canSave = false;
    this.addFuncPrj(this.onSearch);
    this.addFuncNew(this.onAdd);
    this.addFuncDelete(this.onDelete);
  },
  methods: {
    onSearchCallback(row) {
      this.setSheetData(row);
      this.setStatus({
        isNew: false,
        isEditableW: row.level == 2,
        isEditableLG: row.level == 3,
        isEditableMG: row.level == 4,
      });
    },
    onSearch() {
      this.callEvent({name: 'prjWorkTypeCl_onSearch'});
    },
    onAdd() {
      this.$modal.show('workTypeChoice');
    },
    onDelete() {
      this.callEvent({name: 'prjWorkTypeCl_onDelete'});
    },

    async workTypeChoiceConfirm() {
      this.confirm = true;
    },
    async getStdWorkTypeChoiceData(checkedRows) {
      if (!checkedRows.length) {
        this.$alert({title: '표준공종복제', message: '복제할 항목을 선택해 주세요.'});
        return;
      }
      const wcodeList = new Set();
      const lgCodeList = new Set();
      const mgCodeList = new Set();
      checkedRows.forEach(row => {
        if (row['wcode']) wcodeList.add(row['wcode']);
        if (row['lgCode']) lgCodeList.add(row['lgCode']);
        if (row['mgCode']) mgCodeList.add(row['mgCode']);
      });

      const response = await copyStdWorkCl({
        wcodeList: Array.from(wcodeList),
        lgCodeList: Array.from(lgCodeList),
        mgCodeList: Array.from(mgCodeList),
      });

      if (response.data) {
        this.setFocusKeyCode(Array.from(mgCodeList)[0] ?? Array.from(lgCodeList)[0] ?? Array.from(wcodeList[0]));
        this.onSearch();
      }
      this.$modal.hide('workTypeChoice');
    },
    onOtherPrjCopy() {
      this.$modal.show('otherPrjCopy');
    },
    async otherPrjChoiceConfirm() {
      this.callEvent({
        name: 'prjWorkTypeCl_selectClickData_modal',
        param: this.getPrjWorkTypeChoiceData,
      });
    },
    async getPrjWorkTypeChoiceData(checkedRows) {
      let otherPrjCd;
      this.callEvent({name: 'otherPrjCd', param: prjCd => (otherPrjCd = prjCd)});
      const wcodeList = new Set();
      const lgCodeList = new Set();
      const mgCodeList = new Set();
      checkedRows.forEach(row => {
        if (row['wcode']) wcodeList.add(row['wcode']);
        if (row['lgCode']) lgCodeList.add(row['lgCode']);
        if (row['mgCode']) mgCodeList.add(row['mgCode']);
      });

      const response = await copyPrjWorkCl({
        targetPrjCd: otherPrjCd,
        wcodeList: Array.from(wcodeList),
        lgCodeList: Array.from(lgCodeList),
        mgCodeList: Array.from(mgCodeList),
      });

      if (response.data) {
        this.setFocusKeyCode(Array.from(mgCodeList)[0] ?? Array.from(lgCodeList)[0] ?? Array.from(wcodeList[0]));
        this.onSearch();
      }
      this.$modal.hide('otherPrjCopy');
    },
  },
};
</script>

<style scoped></style>
