import {selectInputCount} from '../../api/outsrcDtls.js';
export default that => {
  return {
    Cfg: {
      HeaderMerge: 6,
      CanEdit: 1,
      SearchMode: 0,
    },
    Def: {
      Row: {
        CalcOrder: 'matAmt,labAmt,OprAmt',
      },
    },
    Cols: [
      {
        Header: ['내역\n번호', '내역\n번호'],
        Name: 'itemSeq',
        Type: 'Text',
        Width: 60,
        CanEdit: 1,
        Size: 5,
        Align: 'Center',
        CanEditFormula: e => that.confirm && e.Row.Added == 1,
      },
      {
        Header: ['도급코드', '도급코드'],
        Name: 'itemCode',
        Type: 'Text',
        Width: 145,
        Size: 16,
        CanEditFormula: e => that.confirm && e.Row.Added == 1,
      },
      {
        Header: ['품명', '품명'],
        Name: 'itemName',
        Type: 'Text',
        Width: 160,
        CanEdit: 1,
        CanEditFormula: e => that.confirm && !!e.Row.itemSeq,
      },
      {
        Header: ['규격', '규격'],
        Name: 'ssize',
        Type: 'Text',
        Width: 110,
        CanEdit: 1,
        Align: 'Center',
        CanEditFormula: e => that.confirm && !!e.Row.itemSeq,
      },
      {
        Header: ['단위', '단위'],
        Name: 'unit',
        Type: 'Text',
        Width: 80,
        CanEdit: 0,
        Align: 'Center',
        Button: 'Html',
        ButtonText: '<button class="i_search"></button>',
        WidthPad: 26,
        Format: $getConstants('UNIT').code,
        OnClickSide: () => that.$modal.show('outsrcDtls_unitModal'),
        ButtonFormula: e => {
          if (!!e.Row['itemSeq'] && that.currentDegree == that.maxDgree && that.masterInfo.prjFlag != that.code0265) {
            return 'Html';
          } else {
            return '';
          }
        },
      },
      {
        Header: ['수량', '수량'],
        Name: 'qty',
        Extend: that.$ibsheetHelper.preset.quantity,
        Width: 85,
        CanEditFormula: e => !e.Row.workType && that.confirm,
        OnChange: async e => {
          const response = await selectInputCount({
            chgDegree: that.maxDgree,
            wcode: e.row.wcode,
            headCode: e.row.headCode,
            midCode: e.row.midCode,
            itemSeq: e.row.itemSeq,
            qty: e.row.qty,
          });
          const inputCntGap = response.data.inputCntGap || 0;
          let chgGb = response.data.chgGb;
          if (inputCntGap < 0) {
            if (
              await that.$confirm({
                title: '수량변경',
                message: `${e.row.itemName}의 이미 투입된 수량이 존재하여 최소수량 ${-inputCntGap +
                  e.row.qty}으로 변경하시겠습니까?.`,
              })
            ) {
              e.row.qty = -inputCntGap + e.row.qty;
            } else {
              e.row.qty = e.row.qtyBeforeVal;
              chgGb = '';
            }
            e.sheet.refreshCell(e.row, e.col);
          }
          if (chgGb) {
            that.sheet.setValue(e.row, 'chgGb', '1', 1);
          }
          if (e.row.qtyOrig === e.row.qty) {
            that.sheet.setValue(e.row, 'chgGb', e.row.chgGbOrig, 1);
          }
        },
      },
      {
        Header: ['재료비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'matPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEditFormula: e => !e.Row.workType && that.confirm && that.maxDgree == 1,
      },
      {
        Header: ['재료비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'matAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
        Formula: fr => (fr.Row['itemSeq'] ? fr.Row['qty'] * fr.Row['matPrice'] : fr.Row['matAmt']),
      },
      {
        Header: ['노무비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'labPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEditFormula: e => !e.Row.workType && that.confirm && that.maxDgree == 1,
      },
      {
        Header: ['노무비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'labAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
        Formula: fr => (fr.Row['itemSeq'] ? fr.Row['qty'] * fr.Row['labPrice'] : fr.Row['labAmt']),
      },
      {
        Header: ['경비', {Value: '단가', Color: '#dff9fb'}],
        Name: 'oprPrice',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEditFormula: e => !e.Row.workType && that.confirm && that.maxDgree == 1,
      },
      {
        Header: ['경비', {Value: '금액', Color: '#dff9fb'}],
        Name: 'oprAmt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
        Formula: fr => (fr.Row['itemSeq'] ? fr.Row['qty'] * fr.Row['oprPrice'] : fr.Row['oprAmt']),
      },
      {
        Header: ['합계', {Value: '단가', Color: '#dff9fb'}],
        Name: 'price',
        Extend: that.$ibsheetHelper.preset.unitAmount,
        CanEdit: 0,
        Formula: fr =>
          fr.Row['itemSeq']
            ? (fr.Row['matPrice'] || 0) + (fr.Row['labPrice'] || 0) + (fr.Row['oprPrice'] || 0)
            : fr.Row['price'],
      },
      {
        Header: ['합계', {Value: '금액', Color: '#dff9fb'}],
        Name: 'amt',
        Extend: that.$ibsheetHelper.preset.amount,
        CanEdit: 0,
        Formula: fr =>
          fr.Row['itemSeq']
            ? (fr.Row['matAmt'] || 0) + (fr.Row['labAmt'] || 0) + (fr.Row['oprAmt'] || 0)
            : fr.Row['amt'],
      },
      {
        Header: ['지급자재', '지급자재'],
        Name: 'matGiveYn',
        Type: 'Bool',
        Width: 80,
        Visible: 1,
        CanEditFormula: e => !e.Row.workType && that.confirm,
        OnChange: e => {
          if (e.row[e.col]) {
            e.row['matPriceOrigin'] = e.row.matPrice;
            e.row['labPriceOrigin'] = e.row.labPrice;
            e.row['oprPriceOrigin'] = e.row.oprPrice;
            that.sheet.setValue(e.row, 'matPrice', 0, 1);
            that.sheet.setValue(e.row, 'labPrice', 0, 1);
            that.sheet.setValue(e.row, 'oprPrice', 0, 1);
          } else {
            that.sheet.setValue(e.row, 'matPrice', e.row['matPriceOrigin'] || 0, 1);
            that.sheet.setValue(e.row, 'labPrice', e.row['labPriceOrigin'] || 0, 1);
            that.sheet.setValue(e.row, 'oprPrice', e.row['oprPriceOrigin'] || 0, 1);
          }
        },
      },
      {
        Header: ['변경내역', '변경내역'],
        Name: 'chgGb',
        Type: 'Bool',
        Width: 80,
        CanEdit: 0,
      },
      {Name: 'chgDegree', Visible: 0},
      {Name: 'wcode', Visible: 0},
      {Name: 'headCode', Visible: 0},
      {Name: 'midCode', Visible: 0},
      {Name: 'originItemSeq', Visible: 0},
    ],
  };
};
