export default that => {
  const preset = that.$ibsheetHelper.preset;
  return {
    Cols: [
      {Header: '공종명', Name: 'mgName', RelWidth: 1},
      {Header: '내역명', Name: 'itemName', RelWidth: 1},
      {Header: '규격', Name: 'ssize', RelWidth: 1},
      {Header: '단위', Name: 'unit', Width: 80, Format: $getConstants('UNIT').code},
      {Header: '실적수량', Name: 'actQty', Width: 120, Extend: preset.quantity},
      {
        Header: '실적금액',
        Name: 'actAmt',
        Width: 150,
        Extend: preset.amount,
        Formula: fr => fr.Row['actQty'] * fr.Row['contPlanPrice'],
      },
      {Name: 'contPlanPrice', Visible: 0},
      {Name: 'contPlanQty', Visible: false}, // 도급수량
      {Name: 'totoalFixQty', Visible: false}, // 기성수량
    ],
  };
};
