<template>
  <pmis-content-box :loading="loading">
    <template #title-left>
      <iui-container-new type="table" theme="bullet">
        <colgroup>
          <col width="70px" />
          <col width="80px" />
          <col width="10px" />
          <col width="70px" />
          <col width="80px" />
          <col width="10px" />
          <col width="70px" />
          <col width="250px" />
          <col />
        </colgroup>
        <i-row>
          <i-col-header>기성횟수</i-col-header>
          <i-col>
            <iui-text :value="pxcondInfo.reqCntTemp" :enable="false" width="70px" />
          </i-col>
          <i-spacer />
          <i-col-header>기성년월</i-col-header>
          <i-col>
            <iui-text :value="pxcondInfo.reqYmTemp" :enable="false" width="70px" />
          </i-col>
        </i-row>
      </iui-container-new>
    </template>
    <template #title-right>
      <iui-button value="신규" @click="onAdd" v-if="status.isEnable" />
      <iui-button value="삭제" @click="onDelete" v-if="status.isEnable" />
      <iui-button value="저장" @click="onSave" v-if="!status.isNew && status.isEnable" />
    </template>

    <ib-sheet
      :uid="_uid"
      :options="sheetOpt"
      :loadSearchData="pxcondDetailList"
      @loadSheet="sheet = $event"
      :events="{
        onSearchFinish: sheet_onSearchFinish,
        onBeforeChange: sheet_onBeforeChange,
        onAfterChange: sheet_onAfterChange,
      }"
    />

    <iui-modal name="PxcondTargetListModal" title="금회기성실적등록" :btns="modalBtns" sizeType="size3">
      <PxcondTargetList
        ref="pxcondTargetList"
        :reqStrDate="pxcondInfo.reqStrDate"
        :reqEndDate="pxcondInfo.reqEndDate"
        @reqStrDate="setPxcondInfo({reqStrDate: $event})"
        @reqEndDate="setPxcondInfo({reqEndDate: $event})"
      />
    </iui-modal>
  </pmis-content-box>
</template>

<script>
import difference from 'lodash/difference';
import sheetOpt from './sheetOptions/PxcondDetailInfoSheet.js';
import {selectPxcondDetailDtlsList} from '@/view/pxcondRqestManage/pxcondDtlsRegist/api/pxcondDtlsRegist.js';

import PxcondTargetList from './popup/PxcondTargetList.vue';

export default {
  components: {PxcondTargetList},
  data() {
    return {
      sheetOpt: $addColCheckbox(sheetOpt(this), 2, 'chk', {ColMerge: 0}),
      modalBtns: [{name: '확인', callback: this.pxcondTargetListModalConfirm}],
      loading: false,
    };
  },
  beforeCreate() {
    $mapGetters(this, ['pxcondInfo', 'pxcondDetailList', 'status']);
    $mapMutations(this, ['setPxcondInfo', 'setPxcondDetailList']);
  },
  created() {
    this.addEvent([{name: 'PxcondDetailInfo_getSaveList', func: this.getSaveList}]);
  },
  activated() {
    if (this.status.isNew && !this.pxcondInfo.reqStrDate && !this.pxcondInfo.reqEndDate) {
      this.initSearchDate();
    }

    if (this.status.isNew && this.pxcondDetailList.length === 0) {
      setTimeout(() => this.$modal.show('PxcondTargetListModal'), 300);
    }

    if (!this.status.isNew && this.pxcondDetailList.length === 0) {
      this.onSearch();
    }
  },
  methods: {
    async onSearch() {
      // 선급금인 경우 기성내역 존재하지 않음
      if (this.status.isPrepayment) {
        return;
      }

      this.loading = true;
      // 실적기간 체크
      if (!this.pxcondInfo.reqStrDate || !this.pxcondInfo.reqEndDate) {
        this.initSearchDate();
      }

      try {
        const response = await selectPxcondDetailDtlsList({
          pgmCd: this.pxcondInfo.pgmCd,
          prjCd: this.pxcondInfo.prjCd,
          reqCnt: this.pxcondInfo.reqCnt,
          reqStrDate: this.pxcondInfo.reqStrDate,
          reqEndDate: this.pxcondInfo.reqEndDate,
        });
        this.setPxcondDetailList(response.data);
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    async onAdd() {
      const title = '기성내역';
      const message = '금회 기성내역을 재작성하시겠습니까?<br/>기 작성된 기성내역은 삭제됩니다.';
      if (!(await this.$confirm({title, message}))) {
        return;
      }
      this.$modal.show('PxcondTargetListModal');
    },
    pxcondTargetListModalConfirm() {
      const currentSheetData = this.sheet.getDataRows().filter(row => !!row.itemCode);
      const currentSheetItems = currentSheetData.map(row => row.itemCode);

      const checkedRows = this.$refs.pxcondTargetList.sheet?.getRowsByChecked('chk');
      const checkedRowsItems = checkedRows.map(row => row.itemCode);

      const originRows = currentSheetData.map(row => ({
        mgName: row.mgName,
        itemCode: row.itemCode,
        itemName: row.itemName,
        ssize: row.ssize,
        unit: row.unit,
        actQty: row.actQty,
        lastReqQty: row.lastReqQty,
        curFixCnt: row.curFixCnt,
        reqQty: row.reqQty,
        wcode: row.wcode,
        headCode: row.headCode,
        midCode: row.midCode,
        itemSeq: row.itemSeq,
        billNo: row.billNo,
        taxexeNo: row.taxexeNo,
        contPlanPrice: row.contPlanPrice,
        contPlanQty: row.contPlanQty,
        totoalFixQty: row.totoalFixQty,
      }));
      const addRows = checkedRows
        .filter(row => difference(checkedRowsItems, currentSheetItems).includes(row.itemCode))
        .map(row => ({
          mgName: row.mgName,
          itemCode: row.itemCode,
          itemName: row.itemName,
          ssize: row.ssize,
          unit: row.unit,
          actQty: row.actQty,
          lastReqQty: row.lastReqQty,
          curFixCnt: row.actQty,
          reqQty: 0,
          wcode: row.wcode,
          headCode: row.headCode,
          midCode: row.midCode,
          itemSeq: row.itemSeq,
          billNo: row.billNo,
          taxexeNo: row.taxexeNo,
          contPlanPrice: row.contPlanPrice,
          contPlanQty: row.contPlanQty,
          totoalFixQty: row.totoalFixQty,
        }));

      this.setPxcondDetailList(
        originRows.concat(addRows).sort((a, b) => (a.itemCode < b.itemCode ? -1 : a.itemCode > b.itemCode ? 1 : 0))
      );
      this.$modal.hide('PxcondTargetListModal');
    },
    initSearchDate() {
      const reqYm = this.pxcondInfo.reqYm;
      if (reqYm) {
        if (!this.pxcondInfo.reqStrDate) {
          this.setPxcondInfo({reqStrDate: `${reqYm}01`});
        }
        if (!this.pxcondInfo.reqEndDate) {
          const y = String(reqYm).substr(0, 4);
          const m = String(reqYm).substr(4, 2);
          const d = String(new Date(y, m, 0).getDate());
          this.setPxcondInfo({reqEndDate: `${reqYm}${d}`});
        }
      }
    },
    getSaveList(callback) {
      const saveList = this.sheet.getSaveJson(0).data.map(row => {
        return {
          pgmCd: this.pgmCd,
          prjCd: this.prjCd,
          wcode: row.wcode,
          headCode: row.headCode,
          midCode: row.midCode,
          itemSeq: row.itemSeq,
          itemCode: row.itemCode,
          actQty: row.actQty,
          actAmt: row.actAmt,
          curFixCnt: row.curFixCnt,
          curFixAmt: row.curFixAmt,
          reqQty: row.reqQty,
          reqAmt: row.reqAmt,
        };
      });

      if (typeof callback === 'function') {
        callback(saveList);
      }
    },
    onSave() {
      setTimeout(() => {
        // 수량 입력 후 바로 저장 버튼 클릭했을 시, 계산 시간을 위해 delay
        this.callEvent({name: 'PxcondInfo_save'});
      }, 100);
    },
    async onDelete() {
      const checkedRows = this.sheet.getRowsByChecked('chk').filter(row => !!row.itemCode);

      if (!(await this.$confirm({title: '기성내역 삭제', message: '선택한 내역을 삭제하시겠습니까?'}))) {
        return;
      }

      this.sheet.removeRows(checkedRows);
    },
    calculateAmt() {
      const sheetData = this.sheet.getSaveJson(0).data;
      const wamt = sheetData.reduce((acc, curr) => acc + Number(curr['curFixAmt']), 0);

      this.setPxcondInfo({wamt});

      this.callEvent({name: 'PxcondInfo_calcPxcondAndPrepayAmt', param: 'w'});
    },
    sheet_onSearchFinish(e) {
      this.calculateAmt();
      if (this.status.isEnable) {
        e.sheet.showCol('chk');
      } else {
        e.sheet.hideCol('chk');
      }
      e.sheet.rerender();

      this.onChange(e);

      if (0 < e.sheet.getTotalRowCount()) {
        const canEdit = this.status.isEnable;
        e.sheet.setAttribute({col: 'curFixCnt', attr: 'CanEdit', val: canEdit, render: true});

        const color = this.status.isEnable ? '#E0E0F8' : '';
        this.sheet.setAttribute({col: 'curFixCnt', attr: 'Color', val: color, render: true});
      }
    },
    sheet_onBeforeChange(e) {
      if (e.col === 'curFixCnt') {
        this.sheet.setAttribute(null, 'curFixCnt', 'Type', 'Float', 1);
        const actQty = e.row.actQty;
        const lastReqQty = e.row.lastReqQty;

        const contPlanQty = e.row.contPlanQty;
        const totoalFixQty = e.row.totoalFixQty;
        const maxCurFixCnt =
          Number(Math.floor(Number(contPlanQty) * 100000) - Math.floor(Number(totoalFixQty) * 100000)) / 100000;

        if (Number(e.val) > maxCurFixCnt) {
          this.$alert({
            title: '금회기성수량',
            message: `입력가능한 최대 수량은 ${maxCurFixCnt}입니다.`,
          });
          return e.oldval;
        }

        if (e.val > actQty + lastReqQty) {
          this.$alert({title: '금회기성수량', message: `입력가능한 최대 수량은 ${actQty + lastReqQty}입니다.`});
          return e.oldval;
        }
        return e.val;
      }
    },
    sheet_onAfterChange(e) {
      if (e.col === 'curFixCnt') {
        this.sheet.setAttribute(null, 'curFixCnt', 'Type', 'Text');
        this.calculateAmt();
        this.onChange(e);
      }
    },
    onChange(e) {
      let sumActAmt = 0;
      let sumCurFixAmt = 0;
      let sumReqAmt = 0;

      e.sheet.getDataRows(1).forEach(el => {
        sumActAmt += Number(el.actAmt);
        sumCurFixAmt += Number(el.curFixAmt);
        sumReqAmt += Number(el.reqAmt);
      });
      e.sheet.makeSubTotal([
        {
          stdCol: 'total',
          captionCol: [
            {col: 'chk', val: '총계'},
            {
              col: 'actAmt',
              val: $_numberFormat(sumActAmt, true, null, 'floor', 1),
            },
            {
              col: 'curFixAmt',
              val: $_numberFormat(sumCurFixAmt, true, null, 'floor', 1),
            },
            {
              col: 'reqAmt',
              val: $_numberFormat(sumReqAmt, true, null, 'floor', 1),
            },
          ],
        },
        {
          stdCol: 'mgName',
          sumCols: 'actAmt|reqAmt|curFixAmt',
          captionCol: [{col: 'chk', val: '합계'}],
        },
      ]);
    },
  },
};
</script>

<style></style>
