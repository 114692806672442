<template>
  <pmis-tab-box>
    <template #title>기성실적</template>
    <template #header-left>
      <label>실적기간</label>
      <iui-datepicker :value="reqStrDate" @change="onChangeDate('reqStrDate', $event)" />
      <span>~</span>
      <iui-datepicker :value="reqEndDate" @change="onChangeDate('reqEndDate', $event)" />
      <iui-button value="검색" @click="onSearch" />
    </template>

    <ib-sheet :uid="_uid" :options="sheetOpt" :loadSearchData="loadSearchData" @loadSheet="sheet = $event" />
  </pmis-tab-box>
</template>

<script>
import sheetOpt from './sheetOptions/PxcondTargetListSheet.js';
import {selectPxcondDetailDtlsList} from '@/view/pxcondRqestManage/pxcondDtlsRegist/api/pxcondDtlsRegist.js';

export default {
  props: {
    reqStrDate: {
      Type: String,
      default: '',
    },
    reqEndDate: {
      Type: String,
      default: '',
    },
  },
  data() {
    return {
      sheetOpt: $addColCheckbox(sheetOpt(this)),
      loadSearchData: [],
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    onSearch() {
      selectPxcondDetailDtlsList({reqStrDate: this.reqStrDate, reqEndDate: this.reqEndDate}).then(response => {
        this.loadSearchData = response.data;
      });
    },
    onChangeDate(type, value) {
      // this[type] = value;
      this.$emit(type, value);

      setTimeout(() => this.onSearch(), 300);
    },
  },
};
</script>

<style></style>
